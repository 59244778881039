import React from "react"
import { tv } from "tailwind-variants"
import type { VariantProps } from "tailwind-variants"

import { cn } from "@/lib/utils/classnames"

interface TypographyProps extends React.HTMLAttributes<HTMLElement> {}

export const typographyVariants = tv({
	base: "text-foreground",
	variants: {
		variant: {
			titleLarge: "text-4xl font-bold tracking-tight",
			titleSmall: "text-2xl font-semibold",
			head: "text-xl font-semibold",
			subhead: "text-lg font-medium text-foreground-weaker",
			lead: "text-xl text-foreground-weaker",
			body: "text-base",
			overline:
				"truncate whitespace-nowrap text-sm uppercase tracking-wider opacity-70",
			caption: "text-xs text-foreground-weaker",
			footnote: "text-xs text-foreground-weak",
		},
	},
	defaultVariants: {
		variant: "body",
	},
})

export interface TypographyComponentProps
	extends TypographyProps,
		VariantProps<typeof typographyVariants> {}

const Typography = React.forwardRef<HTMLSpanElement, TypographyComponentProps>(
	({ className, variant, ...props }, ref) => (
		<span
			ref={ref}
			className={cn(typographyVariants({ variant, className }))}
			{...props}
		/>
	),
)
Typography.displayName = "Typography"

const TitleLarge = React.forwardRef<HTMLSpanElement, TypographyProps>(
	(props, ref) => <Typography ref={ref} variant="titleLarge" {...props} />,
)
TitleLarge.displayName = "TitleLarge"

const TitleSmall = React.forwardRef<HTMLSpanElement, TypographyProps>(
	(props, ref) => <Typography ref={ref} variant="titleSmall" {...props} />,
)
TitleSmall.displayName = "TitleSmall"

const Head = React.forwardRef<HTMLSpanElement, TypographyProps>(
	(props, ref) => <Typography ref={ref} variant="head" {...props} />,
)
Head.displayName = "Head"

const Subhead = React.forwardRef<HTMLSpanElement, TypographyProps>(
	(props, ref) => <Typography ref={ref} variant="subhead" {...props} />,
)
Subhead.displayName = "Subhead"

const Lead = React.forwardRef<HTMLSpanElement, TypographyProps>(
	(props, ref) => <Typography ref={ref} variant="lead" {...props} />,
)
Lead.displayName = "Lead"

const Body = React.forwardRef<HTMLSpanElement, TypographyProps>(
	(props, ref) => <Typography ref={ref} variant="body" {...props} />,
)
Body.displayName = "Body"

const Overline = React.forwardRef<HTMLSpanElement, TypographyProps>(
	(props, ref) => <Typography ref={ref} variant="overline" {...props} />,
)
Overline.displayName = "Overline"

const Caption = React.forwardRef<HTMLSpanElement, TypographyProps>(
	(props, ref) => <Typography ref={ref} variant="caption" {...props} />,
)
Caption.displayName = "Caption"

const Footnote = React.forwardRef<HTMLSpanElement, TypographyProps>(
	(props, ref) => <Typography ref={ref} variant="footnote" {...props} />,
)
Footnote.displayName = "Footnote"

const kbdVariants = tv({
	base: "select-none rounded border px-1.5 py-px font-mono text-[0.7rem] font-normal shadow-sm disabled:opacity-50",
	variants: {
		variant: {
			default: "bg-background-weak text-foreground-weak",
			outline: "bg-background text-foreground",
		},
	},
	defaultVariants: {
		variant: "default",
	},
})

export interface KbdProps
	extends React.ComponentPropsWithoutRef<"kbd">,
		VariantProps<typeof kbdVariants> {
	/**
	 * The title of the `abbr` element inside the `kbd` element.
	 * @default undefined
	 * @type string | undefined
	 * @example title="Command"
	 */
	abbrTitle?: string
}

const Kbd = React.forwardRef<HTMLElement, KbdProps>(
	({ abbrTitle, children, className, variant, ...props }, ref) => {
		return (
			<kbd
				className={cn(kbdVariants({ variant, className }))}
				ref={ref}
				{...props}
			>
				{abbrTitle ?
					<abbr title={abbrTitle} className="no-underline">
						{children}
					</abbr>
				:	children}
			</kbd>
		)
	},
)
Kbd.displayName = "Kbd"

export {
	Typography,
	TitleLarge,
	TitleSmall,
	Head,
	Subhead,
	Lead,
	Body,
	Overline,
	Caption,
	Footnote,
	Kbd,
}
